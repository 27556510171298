<template>
  <div v-if="currentItem" class="edit-form">
    <h4>Item</h4>
    <form>
      <div class="form-group">
        <label for="Name">Name</label>
        <input
          type="text"
          class="form-control"
          id="Name"
          v-model="currentItem.Name"
        />
      </div>
      <div class="form-group">
        <label for="Price">Price</label>
        <input
          type="text"
          class="form-control"
          id="Price"
          v-model="currentItem.Price"
        />
      </div>
      <div class="form-group">
        <label for="Number">Item Number</label>
        <input
          type="text"
          class="form-control"
          id="Number"
          v-model="currentItem.ItemNumber"
        />
      </div>
    </form>

    <button
      class="ml-auto p-2 align-self-center m-3 btn btn-sm btn-danger  "
      @click="deleteItem"
    >
      Sletta
    </button>
    <button type="submit" class="btn btn-success" @click="updateItem">
      Goym
    </button>

    <p>{{ message }}</p>
  </div>

  <div v-else>
    <br />
    <p>Please click on a Item...</p>
  </div>
</template>

<script>
import ItemDataService from "../../services/ItemDataService";
export default {
  name: "item",
  data() {
    return {
      currentItem: null,
      message: "",
    };
  },
  methods: {
    getItem(id) {
      ItemDataService.get(id)
        .then((response) => {
          this.currentItem = response.data;
          console.log(response.data);
        })
        .catch((e) => {
          console.log(e);
        });
    },

    updatePublished(status) {
      var data = {
        Id: this.currentItem.Id,
        Name: this.currentItem.Name,
        Price: this.currentItem.Price,
        ItemNumber: this.currentItem.ItemNumber,
      };

      ItemDataService.update(this.currentItem.Id, data)
        .then((response) => {
          console.log(response.data);
          this.currentItem.published = status;
          this.message = "The status was updated successfully!";
        })
        .catch((e) => {
          console.log(e);
        });
    },

    updateItem() {
      ItemDataService.update(this.currentItem.Id, this.currentItem)
        .then((response) => {
          console.log(response.data);
          this.message = "The item was updated successfully!";
        })
        .catch((e) => {
          console.log(e);
        });
    },

    deleteItem() {
      ItemDataService.delete(this.currentItem.Id)
        .then((response) => {
          console.log(response.data);
          this.message = "The item was updated successfully!";
          this.$router.push({ name: "items-list" });
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
  mounted() {
    this.message = "";
    console.log(this.$route.params.id);
    this.getItem(this.$route.params.id);
  },
};
</script>

<style>
.edit-form {
  max-width: 300px;
  margin: auto;
}
</style>
